<template>
    <div class="card card-size">
        <div class="card-body">
            <div class="d-flex flex-column m-b-30">
                <div class="mb-2">
                    <div :id="'carousel-' + product.id" class="carousel slide" data-ride="carousel">
                        <div class="carousel-inner">
                            <div class="carousel-item active">
                                <img class="d-block w-100" :src="getCoverPath(product.cover)" alt="">
                            </div>
                            <div class="carousel-item" v-for="(image, index) in images" :key="index">
                                <img class="d-block w-100" :src="getCoverPathPres(image)" alt="">
                            </div>
                        </div>
                        <template v-if="images.length>0">
                            <a class="carousel-control-prev" :href="'#carousel-' + product.id" role="button" data-slide="prev">
                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            </a>
                            <a class="carousel-control-next" :href="'#carousel-' + product.id" role="button" data-slide="next">
                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            </a>
                        </template>
                    </div>    
                </div>
                <div class="mt-2">
                    <div class="new-arrival-content position-relative">
                        <div class="d-flex flex-column">
                            <h4 class="text-center mb-2">
                                <a href="#" class="text-black">{{
                                    product.name
                                }}</a>
                            </h4>
                            <p class="text-center text-primary precio">
                                <strong
                                    >{{
                                        product?.price_range != undefined
                                            ? product.price_range
                                            : product.price
                                    }}
                                    MXN</strong
                                >
                            </p>
                            <button
                                type="button"
                                class="btn btn-primary mb-2"
                                data-toggle="modal"
                                :data-target="`#basicModal_${product.id}`"
                                @click.prevent="addCart(product)"
                            >
                                Anadir al carrito
                            </button>
                            <!-- Modal -->
                            <div
                                v-if="product.presentations.length > 1"
                                class="modal fade"
                                :id="`basicModal_${product.id}`"
                            >
                                <div class="modal-dialog" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title">
                                                Elige un producto
                                            </h5>
                                            <button
                                                type="button"
                                                class="close"
                                                data-dismiss="modal"
                                            >
                                                <span>&times;</span>
                                            </button>
                                        </div>
                                        <div class="modal-body">
                                            <div class="d-flex flex-column">
                                                <div class="text-center mb-2">
                                                    <div :id="'carousel2-' + product.id" class="carousel slide" data-ride="carousel">
                                                        <div class="carousel-inner">
                                                            <div class="carousel-item active">
                                                                <img class="d-block w-100" :src="getCoverPath(product.cover)" alt="">
                                                            </div>
                                                            <div class="carousel-item" v-for="(image, index) in images" :key="index">
                                                                <img class="d-block w-100" :src="getCoverPathPres(image)" alt="">
                                                            </div>
                                                        </div>
                                                        <template v-if="images.length>0">
                                                            <a class="carousel-control-prev" :href="'#carousel2-' + product.id" role="button" data-slide="prev">
                                                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                                            </a>
                                                            <a class="carousel-control-next" :href="'#carousel2-' + product.id" role="button" data-slide="next">
                                                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                                            </a>
                                                        </template>
                                                    </div>    
                                                </div>
                                                <h4 class="text-center my-2">
                                                    <a
                                                        href="#"
                                                        class="text-black"
                                                        >{{ product.name }}</a
                                                    >
                                                </h4>
                                                <hr />
                                                <div
                                                    class="
                                                        row
                                                        align-items-center
                                                    "
                                                    v-for="presentation in product.presentations"
                                                    :key="presentation.id"
                                                >
                                                    <div class="col-md-8">
                                                        <p>
                                                            <strong>{{
                                                                presentation.description
                                                            }}</strong>
                                                            ${{
                                                                presentation
                                                                    .current_price
                                                                    .amount
                                                            }}MXN
                                                        </p>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <button
                                                            type="button"
                                                            class="
                                                                btn btn-primary
                                                                mb-2
                                                            "
                                                            @click.prevent="addCart(product, presentation)"
                                                        >
                                                            <i
                                                                class="
                                                                    fa
                                                                    fa-shopping-bag
                                                                "
                                                                aria-hidden="true"
                                                            ></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive } from "@vue/reactivity";
import AddCart from "./AddCart.vue";
import { ref } from "vue";

export default {
    components: { AddCart },
    props: {
        product: {
            type: Object,
            required: true,
        },
        addCart: {
            type: Function,
            required: true
        }
    },
    setup(props) {
        const product = reactive(props.product);
        const getCoverPath = (path) =>
            !!path
                ? `${process.env.VUE_APP_API_GATEWAY}/storage/products/${path}`
                : "/assets/images/card/1.png";

        const getCoverPathPres = (path) =>
            !!path 
                ? `${process.env.VUE_APP_API_GATEWAY}/storage/presentations/${path}` 
                : "/assets/images/card/1.png";

        const images = [];

        product.presentations.forEach(presentation =>{
            presentation.images.forEach(image =>{
                if(image.path){
                    images.push(image.path);
                }
            });
        });
        

        return {
            product,
            images,
            getCoverPath,
            getCoverPathPres,
        };
    },
};
</script>

<style scoped>
.carousel-control-next-icon{
    background-color: rgb(94, 94, 94);
}
.carousel-control-prev-icon{
    background-color: rgb(94, 94, 94);
}
.card-size {
    max-width: 414px;
    height: auto;
}
.precio {
    font-size: 18px;
}
hr {
    width: 100% !important;
    background-color: #000 !important;
    height: 1px;
}
</style>
