<template>
    <div>
        <bread-layout :items="breadcrumbLinks"></bread-layout>
        <div class="row" style="margin: 0 auto;">
            <template  v-for="product of products" :key="product.id">
                <div class="col-md-4" v-if="checkPrices(product)">
                    <ProductCard :product="product" :addCart="addCart" />
                </div>
            </template>
        </div>
        <div
            class="d-flex justify-content-center"
            v-if="cart.presentations.length > 0"
        >
            <div class="card card-size">
                <div class="card-body">
                    <div class="d-flex flex-column justify-content-center">
                        <h5>Tu carrito</h5>
                        <p class="text-uppercase text-primary">
                            <strong
                                >Total de articulos #
                                {{ cart.presentations.length }}</strong
                            >
                        </p>
                        <p class="text-uppercase text-primary">
                            <strong>Subtotal $ {{ cart.subtotal }} MXN</strong>
                        </p>
                        <router-link to="/carrito" class="btn btn-primary"
                            >Ir al carrito</router-link
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useStore } from "vuex";
import { useToast } from "vue-toastification";

import { ref, onBeforeMount, computed, onMounted } from "vue";
import BreadLayout from "../../layouts/BreadLayout.vue";
import useGetProducts from "@/composables/products/useGetProducts.js";
import ProductCard from "./ProductCard.vue";
export default {
    components: {
        BreadLayout,
        ProductCard,
    },
    setup() {

        const toast = () =>{
            useToast().success("Producto agregado al carrito", {
                timeout: 3000
            });
        };

        onMounted(() => {
            window.scrollTo(0, 0);
        });

        const breadcrumbLinks = ref([
            {
                text: "Tienda",
                path: "/tienda",
            },
        ]);
        const store = useStore();
        const { getProducts, data: products } = useGetProducts();
        onBeforeMount(() => {
            getProducts();
        });

        const cart = ref(
            JSON.parse(localStorage.getItem("cart")) ?? {
                presentations: [],
                subtotal: 0,
            }
        );

        const checkPrices = (product) => {
            if (product.presentations.length > 1) {
                var validatePrice = false;

                product.presentations.forEach(presentation => {
                    if(presentation.current_price.amount > 0) {
                        validatePrice = true;
                        return;
                    }
                });

                return validatePrice;
            }else{
                return (product.price > 0);
            }
        };

        const addCart = (product, presentation = null) => {
            if (product.presentations.length > 1) {
                if (presentation != null) {
                    const sameProduct = cart.value.presentations.filter(
                        (p) => p.id === presentation.id
                    );
                    if (sameProduct.length > 0) {
                        cart.value.presentations = cart.value.presentations.map(
                            (p) =>
                                p.id === presentation.id
                                    ? { ...p, quantity: (p.quantity += 1) }
                                    : p
                        );
                    } else {
                        cart.value.presentations.push({
                            id: presentation.id,
                            price_id: presentation.current_price.id,
                            name: `${product.name} - ${presentation.description}`,
                            price: presentation.current_price.amount,
                            cover: product.cover,
                            quantity: 1,
                        });
                    }
                    cart.value.subtotal = 0;
                    cart.value.presentations.forEach((p) => {
                        cart.value.subtotal += p.price * p.quantity;
                    });
                    localStorage.setItem("cart", JSON.stringify(cart.value));
                    toast();
                }
                return;
            }
            const sameProduct = cart.value.presentations.filter(
                (p) => p.id === product.presentations[0].id
            );
            if (sameProduct.length > 0) {
                cart.value.presentations = cart.value.presentations.map((p) =>
                    p.id === product.presentations[0].id
                        ? { ...p, quantity: (p.quantity += 1) }
                        : p
                );
            } else {
                cart.value.presentations.push({
                    id: product.presentations[0].id,
                    price_id: product.presentations[0].current_price.id,
                    name: `${product.name} - ${product.presentations[0].description}`,
                    price: product.presentations[0].current_price.amount,
                    quantity: 1,
                    cover: product.cover,
                });
            }
            cart.value.subtotal = 0;
            cart.value.presentations.forEach((p) => {
                cart.value.subtotal += p.price * p.quantity;
            });
            localStorage.setItem("cart", JSON.stringify(cart.value));
            toast();
        };
        return {
            getAuthUser: computed(() => store.state.User.user),
            breadcrumbLinks,
            checkPrices,
            products,
            addCart,
            cart,
        };
    },
};
</script>

<style scoped>
.img {
    max-width: 223px !important;
    max-height: 288px !important;
    object-fit: cover;
}

/* @media (min-width: 979px) {
    
} */
@media (max-width: 390px) {
    .card-size {
        width: 90% !important;
    }
}

.card-size {
    width: 500px;
}
</style>
