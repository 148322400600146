import { ref } from "@vue/reactivity";
import {
    getProductsService,
} from "../../services/products/productsServices";
export default () => {
    const loading = ref(false);
    const data = ref([]);
    const getProducts = async () => {
        NProgress.start();
        loading.value = true;
        const response = await getProductsService();

        if (response.status === 200) {
            data.value = response.data;
        } else {
            Swal.fire("Error!", response.message, "error");
        }
        loading.value = false;
        NProgress.done();
    };
    return {
        loading,
        getProducts,
        data,
    };
};
